<template>
  <section class="about">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper auth p-0 theme-two">
          <div class="row d-flex align-items-stretch">
            <div class="col-md-4 banner-section d-none d-md-flex align-items-stretch justify-content-center">
              <div class="slide-content bg-2"> </div>
            </div>
            <div class="col-12 col-md-8 h-100 bg-white">
              <div class="auto-form-wrapper d-flex align-items-center justify-content-center flex-column">
                <navbar-pages></navbar-pages>

                <h1 class="w-100 mb-4 text-left text-center-responsive">Condiciones de uso</h1>
                <p>
                      
                </p>
                
                <footer-pages></footer-pages>
              </div>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
import NavbarPages from '@/components/NavbarPages'
import FooterPages from '@/components/FooterPages'
export default {
    name: "Conditions",

    components: {
        NavbarPages,
        FooterPages
    }
}
</script>

<style scoped>

</style>
