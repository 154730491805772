<template>
    <div class="d-block w-100 mt-5">
        <div class="row pre-footer px-5 py-3">
            <div class="col-12 col-md-8 text-center-responsive">
                <a href="http://www.famp.es/es/redes-observatorios/redema/" target="_blank">
                    <img src="/static/img/logos/FAMP.png" class="mr-5">
                </a>
                <img src="/static/img/logos/EU_Development_Fund.png">
                <img src="/static/img/logos/SUPPORT.jpg">

            </div>
            <div class="col-12 col-md-4 text-right text-center-responsive">
                <img src="/static/img/logos/uspace_agenda_2030.png">
            </div>
        </div>
        <div class="wrapper mt-5 text-gray">
            <div>
                <p class="footer-text">Copyright © 2021 REDEMA. Todos los derechos reservados.</p>
                <ul class="auth-footer text-gray">
                    <li>
                        <router-link :to="{ name: 'conditions' }">Condiciones de uso</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="js">
export default {
  name: 'FooterPages'
}
</script>

<style scoped lang="scss">
.pre-footer img {
  max-height: 55px;
  max-width: 175px;
}
</style>