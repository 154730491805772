<template>
    <div class="nav-get-started">
        <router-link class="btn btn-link" :to="{ name: 'about' }">Acerca de SUPPORT</router-link>
        <router-link class="btn btn-link" :to="{ name: 'functionalities' }">Funcionalidades del Observatorio</router-link>

        <router-link v-if="isLoggedIn" class="btn get-started-btn" :to="{ name: 'listBuildings' }">Volver al Dashboard</router-link>
        <router-link v-else class="btn get-started-btn" :to="{ name: 'login' }">Volver al Login</router-link>
    </div>
</template>

<script>
import store from '@/store/index'
export default {
  name: "NavbarPages",

  data: () => ({
  }),

  computed: {
    isLoggedIn() {
      let isLoggedIn = store.getters['auth/isAuthenticated'];
      return isLoggedIn;
    },
  },
};
</script>